import Api from './api.service';

export default {
  get: (term = '') =>
    Api.get(
      `terms?page=${term.page}&filter[name]=${term.name}&filter[user_type]=${term.user_type}`
    ),
  getId: (id) => Api.get(`terms/${id}`),
  save: (data) => Api.post(`terms/`, data),
  update: (id, data) => Api.put(`terms/${id}`, data),
  delete: (id) => Api.delete(`terms/${id}`),
};
