<template>
  <div class="appointment-view">
    <app-header icon="passport" title="Term" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <div class="term">
            <form v-if="!isLoading" @submit.prevent>
              <div class="row">
                <div class="col-sm-12 col-lg-6 col-xl-6">
                  <app-input
                    name="name"
                    label="Nome"
                    v-model="term.name"
                  ></app-input>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <b-field label="Tipo de usuário" required>
                    <b-select
                      v-model="term.user_type"
                      placeholder="Selecionar"
                      name="user_type"
                      required
                    >
                      <option
                        v-for="userType in userTypes"
                        :key="userType.id"
                        :value="userType.id"
                      >
                        {{ userType.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <b-field label="Termo de uso">
                    <editor
                      api-key="kg7a6eya1chd9spudd90s3udrfu7ncpfblexxmdqcj0n93h8"
                      v-model="term.term"
                      :init="{ height: 300 }"
                    ></editor>
                  </b-field>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <b-button
                    :disabled="$v.$invalid"
                    @click="onSubmit()"
                    type="is-primary"
                    >Salvar</b-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TermsService from '@/services/terms.service.js';
import { required } from 'vuelidate/lib/validators';
import Editor from '@tinymce/tinymce-vue';
export default {
  components: {
    editor: Editor,
  },
  data: () => ({
    errors: {},
    isLoading: true,
    title: '',
    term: {},
    userTypes: [],
  }),
  validations: {
    term: {
      name: {
        required,
      },
      user_type: {
        required,
      },
    },
  },
  methods: {
    loadUserTypes() {
      this.userTypes = [
        { id: 1, name: 'Médico' },
        { id: 2, name: 'Paciente' },
        { id: 3, name: 'Secretária' },
      ];
    },
    loadTerm(id) {
      if (typeof id != 'undefined') {
        this.isLoading = true;
        TermsService.getId(id)
          .then(({ data }) => (this.term = data))
          .finally(() => (this.isLoading = false));
      } else {
        this.isLoading = false;
      }
    },
    onSubmit() {
      this.errors = {};
      !this.id && this.save(this.term);
      this.id && this.update(this.id, this.term);
    },
    save(data) {
      this.isSaving = true;
      TermsService.save(data)
        .then(() => {
          this.$buefy.snackbar.open('Termo cadastrado com sucesso!');
          this.$router.push({ name: 'terms.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Termo.',
            'error'
          );
          this.errors = response.data.message;
        })
        .finally(() => (this.isSaving = false));
    },
    update(id, data) {
      this.isSaving = true;
      TermsService.update(id, data)
        .then(() => {
          this.$buefy.snackbar.open('Termo atualizado com sucesso!');
          this.$router.push({ name: 'terms.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Termo.',
            'error'
          );
          this.errors = response.data.message;
        })
        .finally(() => (this.isSaving = false));
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.id = this.$route.params.id;
    this.loadUserTypes();
    this.loadTerm(id);
  },
};
</script>

<style lang="scss" scoped>
.patient {
  display: flex;

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;

    img {
      object-fit: cover;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .links {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
